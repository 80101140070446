.tier-user-zone {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    padding: 0.5em 0.25em 0 0.7em;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: #736b5e;
}
