.user-settings-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    margin-top: 2rem;
    max-width: 27em;
    padding-right: 1em;
    overflow: auto;
}

.user-settings-container-mobile {
    padding: 1em;
    margin: 0;
}

.user-settings-restricted {
    color: red;
    font-weight: bold;
    font-size: larger;
    margin-bottom: 2em;
    align-self: center;
}

.user-settings-title {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1em;
}

.user-settings-spacer {
    height: 2em;
}

.user-settings-subtext {
    margin-top: 0.5em;
    color: gray;
    font-size: small;
}

.user-settings-upload-button {
    align-self: center;
    margin-top: 1em;
    margin-bottom: 1.5em;
}

.user-settings-actions-container {
    display: flex;
    flex-direction: row;
    margin-top: 3em;
    width: 100%;
    justify-content: space-evenly;
}

.user-settings-save-button {
    width: 10em;
}

.user-settings-logout-button {
    width: 10em;
}

.user-settings-label-input {
    margin-bottom: 0.5em;
}

