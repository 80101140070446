.profile-page-split {
    display: flex;
    flex-direction: row;
    color: white;
    margin-top: 3rem;
}

.profile-page-split-mobile {
    flex-direction: column;
    gap: 2em;
    margin-top: 0;
    padding: 1em;
}

.profile-left-panel {
    display: flex;
    margin: 1em 1em 0 0;
    padding: 0 1em 0 0;
    min-width: 50%;
    overflow-y: auto;
    justify-content: center;
    align-items: flex-start;
}

.profile-right-panel {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
}

