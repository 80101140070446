.user-move-modal-main {
    background: #202324;
    color: white;
}

.user-move-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.user-move-modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.user-move-modal-single-button {
    justify-content: flex-end;
}

.user-move-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.user-move-modal-title {
    font-weight: bold;
    font-size: 1.3em;
}

.user-move-modal-tier-picker {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    gap: 0.5em;
}

.user-move-modal-user-container {
    align-self: center;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.user-move-modal-tier-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}

.user-move-modal-position {
    margin-bottom: 0.5em;
    text-align: center;
}

.user-move-modal-position-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-move-modal-range {
    width: 30em;
    margin-bottom: 1em;
}

.user-move-modal-range-mobile {
    width: 100%;
}

