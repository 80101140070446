.user-table {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

th, td {
    text-align: center;
    border-color: #736b5e;
    border-style: solid;
    border-width: 1px;
}

thead tr th {
    padding: 1em 0 1em 0;
}