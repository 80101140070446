.tier-color-circle {
    width: 40px;
    height: 40px;
    margin: 5px;
    display: inline-block;
    transition: box-shadow .1s;
    cursor: pointer;
    border-radius: 50%;
    border: solid 2px gray;
}

.tier-color-selected {
    border: solid 4px blue;
}

.tier-color-circles-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.tier-color-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}

.tier-color-picker-name-container {
    min-height: 6em;
    min-width: 7em;
    max-width: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tier-color-picker-rgb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tier-color-picker-input {
    width: 4em;
    margin-left: 0.5em;
    margin-right: 1em;
}