.tierlist-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
    padding-top: 1em;
}

.tierlist-button-group {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.tierlist-button-group-mobile {
    gap: 0.5em;
}