.user-backup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.user-backup-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1em;
    width: 100%;
}