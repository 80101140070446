.status-container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-bottom: 0.5em;
}

.status-text {
}

.status-error {
    font-weight: bold;
    color: darkred;
}

.status-success {
    font-weight: bold;
    color: darkgreen;
}

.status-subtext {
    margin-top: 0.5em;
    color: gray;
    font-size: small;
}