.tierlist-header-title-input {
    align-self: center;
    justify-self: center;
    width: 27em;
    border-radius: 10px;
}

.tierlist-header-title-input-mobile {
    width: calc(50% - 0.5em);
    font-size: 0.8em;
    margin-right: 0.5em;
}

.tierlist-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.tierlist-header-container-mobile {
    margin-bottom: 0.5em;
}

.tierlist-header-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.tierlist-header-buttons-container-mobile {
    gap: 0.5em;
}