.tierlist-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tierlist-tiers-container {
    background: #202324;
    display: flex;
    flex-direction: column;
}

.tierlist-tier-container {
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: #736b5e;
    display: flex;
    flex-direction: row;
}

.tierlist-tier-container:last-child {
    border-width: 1px;
}

.tierlist-tier-arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 0.1em;
    height: 100%;
}

.tierlist-icon-arrow {
    color: white;
    width: 2em;
    height: 2em;
    cursor: pointer;
}

.tierlist-icon-arrow-mobile {
    width: 1.2em;
    height: 1.2em;
}

.tierlist-icon-gear {
    color: white;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    margin-right: 0.5em;
}

.tierlist-icon-gear-mobile {
    width: 1em;
    height: 1em;
}

.tierlist-bottom-spacer {
    min-height: 5em;
}

.tierlist-bottom-spacer-mobile {
    min-height: 1em;
}

.tierlist-actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tierlist-export {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #202324;
}