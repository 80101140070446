.admin-menu-modal-main {
    background: #202324;
    color: white;
}

.admin-menu-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding: 2em;
}

.admin-menu-modal-button {
    width: 20em;
    background: unset;
}

