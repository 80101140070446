.user-line-container:nth-child(odd) {
    background: rgb(50, 50, 50);
}

.user-line-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.user-line-actions-wrapper {
    display: flex;
    flex-direction: column;
}

.user-line-input-wrapper {
    padding: 0 1.5em 0 1.5em;
}

.user-line-status {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
}

.user-line-status-error {
    font-weight: bold;
    color: darkred;
}

.user-line-status-success {
    font-weight: bold;
    color: darkgreen;
}
