.user-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 3px;
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    flex: none;
    cursor: pointer;
    padding: 2px;
}

.user-icon-container-mobile {
    height: 50px;
    width: 50px;
}

.user-icon-container-render {
    height: 120px;
    width: 120px;
}

.user-icon-action-wrapper {
    height: 25px;
    width: 25px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-icon-action-wrapper-mobile {
    height: 20px;
    width: 20px;
    border-radius: 10px;
}

.user-icon-action-wrapper-delete {
    background: darkred;
    justify-self: flex-end;
}

.user-icon-action-wrapper-copy {
    background: rgb(8, 82, 155);
    justify-self: flex-start;
}

.user-icon-action {
    height: 15px;
    width: 15px;
}

.user-icon-action-mobile {
    height: 10px;
    width: 10px;
}