.login-form-container {
    display: flex;
    flex-direction: column;
}

.login-form-title {
    margin-bottom: 1em;
    font-size: large;
    font-weight: bold;
}

.login-form-input {
    border-radius: 7px;
    width: 15em;
}

.login-form-spacer {
    height: 3em;
}

.login-form-button {
    margin-top: 3em;
    width: 7em;
    align-self: center;
}

.login-form-error {
    color: darkred;
    font-weight: bold;
    align-self: center;
    margin-top: 1.5em;
}