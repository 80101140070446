.tier-name-container {
    min-height: 6em;
    min-width: 7em;
    max-width: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    hyphens: auto;
    color: black;
    text-align: center;
    overflow: hidden;
}

.tier-name-container-render {
    font-size: 1.5em;
}

.tier-name-image {
    width: 100%;
    object-position: center;
    object-fit: cover;
}

.tier-name-container-mobile {
    min-height: 65px;
    min-width: 60px;
    max-width: 60px;
    padding: 0.2em;
    font-size: 0.75em;
    background-size: 60px;
}

.tier-name-input {
    color: black !important;
    text-align: center;
    padding: 0;
    border-style: none !important;
}

.tier-name-input-mobile {
    font-size: 1em;
}