.icons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
}

.icons-container-not-empty {
    flex: unset;
    align-items: start;
}

.icons-container-not-empty-mobile {
    justify-content: space-evenly;
}

.user-drag-filler {
    flex: 1;
}