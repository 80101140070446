.page-content {
    display: flex;
    flex-direction: column;
    color: white;
    width: 100vw;
    background: #202324;
}

.page-root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.page-root-mobile {
    height: unset;
    overflow: unset;
}

.page-content-wrapper {
    background: #202324;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.page-content-wrapper-mobile {
    height: unset;
}
