.admin-page-text {
    margin-top: 3rem;
    font-size: larger;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.admin-page-text-warning {
    color: darkred;
}

.admin-table-wrapper {
    max-height: calc(100vh - 4em);
    padding: 0 1rem 0 1rem;
    overflow-y: auto;
    margin: 1rem;
}