.random-modal-main {
    background: #202324;
    color: white;
}

.random-modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.random-modal-save-button {
    margin-left: 1em;
}

.random-modal-body {
    display: flex;
    flex-direction: column;
}

.random-modal-form-title {
    margin-bottom: 0.5em;
    font-size: large;
    font-weight: bold;
}

.random-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.random-modal-title {
    font-weight: bold;
    font-size: 1.3em;
}

.random-modal-spacer {
    height: 2em;
}

.random-modal-subtext {
    margin-top: 0.5em;
    color: gray;
    font-size: small;
}

.random-modal-range {
    width: 100%;
}