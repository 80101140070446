body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #202324;
    color: #aba499;
}


@media screen and (max-width: 400px) {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
    }
}

::-webkit-scrollbar-track {
    background: #181a1b;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #454a4d;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus {
    border: none;
    outline: none;
}

.form-control {
    background: #202324;
}

