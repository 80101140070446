.user-search-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-search-icon {
    color: white;
    height: 1.5em;
    margin-right: 1em;
}

.user-search-icon-mobile {
    height: 1em;
    margin-right: 0.5em;
}

.user-search-input {
    border-radius: 15px;
    width: 15em;
}

.user-search-input-mobile {
    width: 10em;
    font-size: 0.75em;
}