.vrg-button {
    border-radius: 15px;
    color: white;
    background: rgb(8, 82, 155);
    border-color: rgb(8, 82, 155);
    font-weight: bold;
}

.vrg-button-mobile {
    font-weight: normal;
    font-size: 0.75em;
}

.vrg-button:hover {
    background: blue;
}

.vrg-button-danger {
    background: darkred;
    border-color: darkred;
}

.vrg-button-danger:hover {
    background: red;
}

.vrg-button-secondary {
    background: rgb(59, 59, 59);
    border-color: rgb(59, 59, 59);
}

.vrg-button-secondary:hover {
    background: rgb(133, 133, 133);
}

.vrg-button-disabled {
    cursor: not-allowed;
}
