.tierlist-page-content {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    max-height: calc(100vh - 4em);
    padding: 1rem;
    width: 100vw;
}

.tierlist-page-content-mobile {
    padding: 0.5rem;
    max-height: unset;
    flex-direction: column;
}

.tierlist-left-panel {
    display: flex;
    margin: 0 1em 0 0;
    padding: 0 1em 0 0;
    min-width: 50%;
    max-width: 50%;
    overflow-y: auto;
}

.tierlist-left-panel-mobile {
    min-width: 100%;
    margin: 0;
    padding: 0;
}

.tierlist-right-panel {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.tierlist-right-panel-mobile {
    width: unset;
    height: unset;
}

