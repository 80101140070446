.vrg-form-control {
    background-color: #202324;
    color: white;
}

.vrg-form-control:focus {
    background-color: #202324;
    color: white;
}


