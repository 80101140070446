.profile-info-title {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1em;
}

.profile-info-text {
    margin-bottom: 2em;
}

.profile-info-highlight {
    font-weight: bolder;
}

.profile-info-container {
    max-width: 32em;
}