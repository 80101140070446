.picture-updater-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding-top: 1em;
    margin-bottom: 1em;
    min-width: 15em;
}

.picture-updater-new-image {
    display: inline-block;
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: cover;
    flex: none;
    cursor: pointer;
}

.picture-updater-new-image-small {
    height: 40px;
    width: 40px;
}