.header-container {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #736b5e;
    background: black;
    background-origin: content-box;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.3em 2em 0.3em 2em;
    min-height: 3.5em;
    max-height: 3.5em;
}

.header-container-mobile {
    min-height: 2.5em;
    max-height: 2.5em;
    padding: 0.3em 0.2em 0.3em 1em;
    overflow: hidden;
}

.header-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.header-admin-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-logo-mobile {
    height: 70%;
    margin-right: 1em;
}

.header-title {
    font-size: larger;
    margin-right: 1em;
}

.header-button {
    border-style: solid;
    font-size: larger;
    border-width: 0 0 0 1px;
    border-color: #736b5e;
    padding-left: 1em;
    padding-right: 1em;
}

.header-button-mobile {
    font-size: 1em;
}

.header-button:hover {
    color: lightblue;
}

.header-menu-button {
    background: unset;
}