.confirm-modal-main {
    background: #202324;
    color: white;
}

.confirm-modal-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.confirm-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.confirm-modal-title {
    font-weight: bold;
    font-size: 1.3em;
}

