.setup-modal-main {
    background: #202324;
    color: white;
}

.tier-bottom-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.setup-modal-save-button {
    margin-left: 1em;
}

.setup-modal-body {
    display: flex;
    flex-direction: column;
}

.setup-modal-form-title {
    margin-bottom: 0.5em;
    font-size: large;
    font-weight: bold;
}

.setup-modal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.setup-modal-title {
    font-weight: bold;
    font-size: 1.3em;
}

.setup-modal-spacer {
    height: 2em;
}

.setup-modal-subtext {
    margin-top: 0.5em;
    color: gray;
    font-size: small;
}

.setup-modal-action {
    width: 10em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.setup-modal-warning {
    color: darkred;
    margin-top: 1em;
    font-weight: bold;
}

.setup-modal-range {
    width: 100%;
}